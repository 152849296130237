<template>
  <br>
  <div class="home home--static">
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="section__carousel-wrap">
              <div id="carousel-example" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner row w-100 mx-auto" role="listbox">
                  <div class="carousel-item col-12 col-sm-6 col-md-4 col-lg-3 " :class="{ active: index === 0 }"
                    v-for="(publish, index) in carousel" :key="index">
                    <a @click="getFeed(publish)" class="pointer">
                      <img :src="publish.carsoul_img" class="img-fluid mx-auto d-block" alt="img1">
                    </a>
                    <h3 class="interview__title pointer" style="float: right;margin-right: 10px;"><a
                        @click="getFeed(publish)">{{ publish.title_ar }}</a></h3>
                  </div>

                </div>
                <a class="carousel-control-prev" style="border-radius:  10px 0 0 10px ;" href="#carousel-example"
                  role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" style="border-radius: 0 10px 10px 0;" href="#carousel-example"
                  role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="pc-tab">
    <input id="tab0" type="radio" name="pct" />
    <input id="tab1" type="radio" name="pct" />
    <input id="tab2" type="radio" name="pct" />
    <input id="tab3" type="radio" name="pct" />
    <input checked="checked" id="tab4" type="radio" name="pct" />
    <input id="tab5" type="radio" name="pct" />
    <input id="tab6" type="radio" name="pct" />

    <nav>


      <ul class="slider-radio">
        <!-- <li class="tab0">
          <label  for="tab0">كل المحتوي</label>
        </li> -->
        <li class="tab1">
          <label for="tab1">صحة</label>
        </li>
        <li class="tab2">
          <label for="tab2">تعليم</label>
        </li>
        <li class="tab3">
          <label for="tab3">قانون</label>
        </li>
        <li class="tab4">
          <label for="tab4">فن و دراما</label>
        </li>
        <!-- <li class="tab5">
          <label for="tab5">كوميديا</label>
        </li>
        <li class="tab6">
          <label for="tab6">رياضة</label>
        </li> -->
      </ul>


    </nav>

    <section>
      <!-- <div class="tab0">
      <div class="container"  >
      <div class="row">
      <div class="col-12">
        <div class="row row--grid" style="margin-right: 5px;margin-left: 5px;">
        <template v-for="publish in All" :key="publish.id">
                    <div class="col-6 col-sm-4 col-lg-3 col-xl-2" >
                        <div class="card" >
                            <h3 class="card__title pointer"><a @click="getFeed(publish)">   {{ publish.title_ar }}  </a></h3>
                            <a @click="getFeed(publish)" class="card__cover pointer">
                                    <img :src="publish.img" alt="">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </a>                  
                            <ul class="card__list pointer">
                                <li>زين</li>
                                <li>{{ publish.name_ar }}</li>
                                <li>{{ publish.year }}</li>
                            </ul>
                        </div>
                    </div>
            </template>
        </div>
        </div>
        </div>
        </div>
      </div> -->
      <div class="tab1">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="row row--grid" style="margin-right: 5px;margin-left: 5px;">
                <template v-for="publish in health" :key="publish.id">
                  <div class="col-6 col-sm-4 col-lg-3 col-xl-2">
                    <div class="cardBox">

                      <div class="card">
                        <h3 class="card__title pointer"><a @click="getFeed(publish)"> {{ publish.title_ar }} </a></h3>
                        <a @click="getFeed(publish)" class="card__cover pointer">
                          <img :src="publish.img" alt="">
                          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z"
                              stroke-linecap="round" stroke-linejoin="round" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z"
                              stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                        <ul class="card__list pointer">
                          <li>زين</li>
                          <li>{{ publish.name_ar }}</li>
                          <li>{{ publish.year }}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab2">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="row row--grid" style="margin-right: 5px;margin-left: 5px;">
                <template v-for="publish in education" :key="publish.id">
                  <div class="col-6 col-sm-4 col-lg-3 col-xl-2">
                    <div class="cardBox">

                      <div class="card">
                        <h3 class="card__title pointer"><a @click="getFeed(publish)"> {{ publish.title_ar }} </a></h3>
                        <a @click="getFeed(publish)" class="card__cover pointer">
                          <img :src="publish.img" alt="">
                          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z"
                              stroke-linecap="round" stroke-linejoin="round" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z"
                              stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                        <ul class="card__list pointer">
                          <li>زين</li>
                          <li>{{ publish.name_ar }}</li>
                          <li>{{ publish.year }}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab3">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="row row--grid" style="margin-right: 5px;margin-left: 5px;">
                <template v-for="publish in low" :key="publish.id">
                  <div class="col-6 col-sm-4 col-lg-3 col-xl-2">
                    <div class="cardBox">

                      <div class="card">
                        <h3 class="card__title pointer"><a @click="getFeed(publish)"> {{ publish.title_ar }} </a></h3>
                        <a @click="getFeed(publish)" class="card__cover pointer">
                          <img :src="publish.img" alt="">
                          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z"
                              stroke-linecap="round" stroke-linejoin="round" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z"
                              stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                        <ul class="card__list pointer">
                          <li>زين</li>
                          <li>{{ publish.name_ar }}</li>
                          <li>{{ publish.year }}</li>
                        </ul>
                      </div>
                    </div>


                  </div>

                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab4">
        <div class="container">
          <div class="row">

            <div class="col-12" dir="rtl">
              <!-- <h3 class="card__title pointer" style="text-align: right;color: #0E6BA2;text-shadow: 1px 1px 2px #73707082;margin-bottom: 10px;">  الفنانين </h3> -->
              <div class="yt__filter-slider-wrapper" style="margin-top: 1vh;">
                <div class="yt__filter-slider">
                  <ul class="yt__filter-list">
                    <template v-for="publish in ArtistsComedy" :key="publish.id">
                      <li class="yt__filter-list-item ">
                        <h5 class="card__title pointer"
                          style="font-family: 'Cairo', sans-serif;font-weight: 600;font-size: 14px;color: #9b9191;margin-top: 0;margin-bottom: 0;transition: 0.5s;display: block;width: 100%;overflow: hidden;white-space: nowrap;-o-text-overflow: ellipsis;text-overflow: ellipsis;word-wrap: break-word;text-shadow: 1px 1px 2px #00000008;">
                          <a @click="GoToArtistsContent(publish)"> {{ publish.cp_name }} </a>
                        </h5>
                      </li>

                    </template>
                  </ul>
                </div>
              </div>



            </div>

            <div class="col-12">
              <div class="col-12">

                <div class="cardBox" style="height: 6vh;">
                  <div class="card" style="width: 96%;height: 80%;">

                    <h3 class="card__title" style="padding-bottom: 5px;"> المسلسلات</h3>

                  </div>
                </div>

              </div>
              <div class="section__carousel-wrap">
                <div id="carousel-example2" class="carousel slide" data-ride="carousel">
                  <div class="carousel-inner row w-100 mx-auto" role="listbox">
                    <div class="carousel-item col-12 col-sm-6 col-md-4 col-lg-3 " :class="{ active: index === 0 }"
                      v-for="(publish, index) in Series" :key="index">


                      <div class="cardBox">

                        <div class="card">
                          <a @click="GoToSeriesContent(publish)" class="card__cover">
                            <img :src="publish.series_img" alt="">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z"
                                stroke-linecap="round" stroke-linejoin="round" />
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z"
                                stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                          <h3 class="card__title"><a @click="GoToSeriesContent(publish)">{{ publish.series_name_ar }}</a>
                          </h3>

                          <ul class="card__list" style="justify-content: center;">
                            <!-- <li>الفنان /</li> -->
                            <li> مع {{ publish.cp_name }}</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                  </div>
                  <a class="carousel-control-prev" style="border-radius: 30px;height: 60px !important;top: 28%;z-index: 555;background-image: linear-gradient(0deg, #047072 0, #00c9c9);opacity: 1;" href="#carousel-example2"
                    role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a class="carousel-control-next" style="border-radius: 30px;height: 60px !important;top: 28%;z-index: 555;background-image: linear-gradient(0deg, #047072 0, #00c9c9);opacity: 1;" href="#carousel-example2"
                    role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
              </div>



              <!-- <div class="row row--grid">
                <div class="col-12 col-sm-4 col-lg-3 col-xl-4" v-for="publish in Series" :key="publish.id">
                  <div class="cardBox">

                    <div class="card">
                      <a @click="GoToSeriesContent(publish)" class="card__cover">
                        <img :src="publish.series_img" alt="">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z"
                            stroke-linecap="round" stroke-linejoin="round" />
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z"
                            stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </a>
                      <h3 class="card__title"><a @click="GoToSeriesContent(publish)">{{ publish.series_name_ar }}</a></h3>

                      <ul class="card__list" style="justify-content: center;">
                        <li> مع {{ publish.cp_name }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>

            <div class="col-12">

              <div class="col-12">

                <div class="cardBox" style="height: 6vh;">
                  <div class="card" style="width: 96%;height: 80%;">

                    <h3 class="card__title" style="padding-bottom: 5px;">قائمة الفــنانين</h3>

                  </div>
                </div>

              </div>
              <div class="row row--grid" style="margin-right: 5px;margin-left: 5px;">
                <template v-for="publish in ArtistsComedy" :key="publish.id">
                  <div class="col-6 col-sm-4 col-lg-3 col-xl-2">
                    <div class="cardBox">
                      <div class="card">
                        <a @click="GoToArtistsContent(publish)" class="card__cover" style="height: 230px;">
                          <img :src="publish.img" alt="">
                          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z"
                              stroke-linecap="round" stroke-linejoin="round" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z"
                              stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                        <h3 class="card__title" style="padding-bottom: 9px;margin-bottom: 7px;"><a
                            @click="GoToArtistsContent(publish)">{{ publish.cp_name }}</a></h3>
                        <ul style="justify-content: center;" class="card__list">
                          <li style="margin-left: 0;">{{ publish.name_ar }}</li>
                          <li></li>
                        </ul>

                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="tab4">
      <div class="container" >
      <div class="row">
      <div class="col-12">
        <div class="row row--grid" style="margin-right: 5px;margin-left: 5px;">
        <template v-for="publish in art" :key="publish.id">
                    <div class="col-6 col-sm-4 col-lg-3 col-xl-2" >
                        <div class="card" >
                            <h3 class="card__title pointer"><a @click="getFeed(publish)">   {{ publish.title_ar }}  </a></h3>
                            <a @click="getFeed(publish)" class="card__cover pointer">
                                    <img v-lazy="publish.img" alt="">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </a>                  
                            <ul class="card__list pointer">
                                <li>زين</li>
                                <li>{{ publish.name_ar }}</li>
                                <li>{{ publish.year }}</li>
                            </ul>
                        </div>
                    </div>
            </template>
        </div>
    



        </div>
        </div>
        </div>
      </div> -->
    </section>
  </div>

  <!-- end catalog -->
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import config from '@/Api/config';
import { useRouter } from "vue-router"
import { useCookie } from 'vue-cookie-next'
export default {
  name: 'HomePage',
  data() {
    return {
      health: null,
      education: null,
      low: null,
      art: null,
      comedy: null,
      sport: null,
    };
  },
  watch: {
    $route() {
      window.scrollTo(0, 0);
      // this.$router.go();
    },
  },
  created() {
    //  const toast = useToast();
    let urlParams = new URLSearchParams(window.location.search);
    //console.log(urlParams.has('harry')); // true
    if (urlParams.has('msisdn') || urlParams.has('pd')) {
      this.$cookie.setCookie('mdn', urlParams.get('msisdn'));
      this.$cookie.setCookie('PD', urlParams.get('pd'));



    }

    //console.log(urlParams.get('MSISDN')); // "MyParam"
    //  console.log(urlParams.get('pd')); // "MyParam"

    //  this.$router.push("/");

  },

  async setup() {
    const router = useRouter();
    const cookie = useCookie();

    const carousel = await axios.get('GetAllContent.php?Content&LIMIT=15&OFFSET=0', {
      headers: {
        'Authorization': config.authHeader,
      },
    }).then((response) => { return response.data.Content; });
    const Artists = await axios.get('GetAllArtists.php', {
      headers: {
        'Authorization': config.authHeader,
      },
    }).then((response) => { return response.data.Artists; });
    const ArtistsComedy = await axios.get('GetAllArtists.php', {
      headers: {
        'Authorization': config.authHeader,
      },
    }).then((response) => { return response.data.Artists; });
    const Series = await axios.get('GetAllSeries.php', {
      headers: {
        'Authorization': config.authHeader,
      },
    }).then((response) => { return response.data.Series; });

    const GoToArtistsContent = (publish) => {
      if (cookie.isCookieAvailable("artist_id")) {
        cookie.removeCookie("artist_id")
      }
      cookie.setCookie('artist_id', publish.id)
      router.push("/ArtistContent");
    };

    const GoToSeriesContent = (publish) => {
      if (cookie.isCookieAvailable("series_id")) {
        cookie.removeCookie("series_id")
      }
      cookie.setCookie('series_id', publish.id)
      router.push("/SeriesContent");
    };

    return { carousel, Artists, ArtistsComedy, Series, GoToArtistsContent, GoToSeriesContent };
  },
  mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    //console.log(urlParams.has('harry')); // true
    if (urlParams.has('msisdn') && urlParams.has('pd')) {
        const toast = useToast();
        toast.success("مرحبا بك في منصة نشوف ", {
          timeout: 2000
        });

        if (this.$cookie.getCookie("landing_content") != null) {
          this.$cookie.setCookie("Content_id", this.$cookie.getCookie("landing_content"));
          this.$cookie.removeCookie("landing_content");
          setTimeout(() =>
            this.$router.push({
              path: "/Content"
            }),
            2000
          );
        } else {
          setTimeout(() =>

            this.$router.push({
              path: "/"
            }),
            2000
          );
        }

      
      //  this.$router.push({ path: "/"
      //       });
      //       this.$router.go();


    }
    if (this.$cookie.getCookie("landing_content") != null) {
      //  console.log("1111111111111111111111111111111111111111");
      this.$cookie.setCookie("Content_id", this.$cookie.getCookie("landing_content"));
      this.$cookie.removeCookie("landing_content");
      setTimeout(() =>
        this.$router.push({
          path: "/Content"
        }),
        10
      );
    }

    // const toast = useToast();



    axios
      .get('GetAllContent.php?Content&LIMIT=60&OFFSET=0', {
        headers: {
          'Authorization': config.authHeader,
        },
      })
      .then((response) => (this.All = response.data.Content));
    axios.get('GetContentByCategory.php?cat_id=1&LIMIT=15&OFFSET=0', {
      headers: {
        'Authorization': config.authHeader,
      },
    }).then((response) => (this.health = response.data.Content));
    axios
      .get('GetContentByCategory.php?cat_id=2&LIMIT=15&OFFSET=0', {
        headers: {
          'Authorization': config.authHeader,
        },
      })
      .then((response) => (this.education = response.data.Content));
    axios
      .get('GetContentByCategory.php?cat_id=3&LIMIT=15&OFFSET=0', {
        headers: {
          'Authorization': config.authHeader,
        },
      })
      .then((response) => (this.low = response.data.Content));
    axios
      .get('GetContentByCategory.php?cat_id=4&LIMIT=15&OFFSET=0', {
        headers: {
          'Authorization': config.authHeader,
        },
      })
      .then((response) => (this.art = response.data.Content));
    axios
      .get('GetContentByCategory.php?cat_id=5&LIMIT=15&OFFSET=0', {
        headers: {
          'Authorization': config.authHeader,
        },
      })
      .then((response) => (this.comedy = response.data.Content));
    axios
      .get('GetContentByCategory.php?cat_id=6&LIMIT=15&OFFSET=0', {
        headers: {
          'Authorization': config.authHeader,
        },
      })
      .then((response) => (this.sport = response.data.Content));


  },
  methods: {
    getFeed: function (publish) {
      this.$cookie.setCookie('Content_id', publish.id);
      this.$cookie.setCookie('cat_id', publish.cat_id);
      this.$cookie.setCookie('product_code', publish.product_code);
      // this.$router.push({ name: "Trailer" });
      if (this.$cookie.isCookieAvailable("product_code") && this.$cookie.isCookieAvailable("mdn")) {
        axios.get(
          "https://nashoof.sd.zain.com/DSPAPIs/APIs/DSPCheckLogin.php?msisdn=" + this.$cookie.getCookie("mdn") 
          , {
            headers: {
              'Authorization': config.authHeader,
            },
          }).then((response) => {
            if (response.data.status == 1 && response.data.remming_minutes > 0) {
              axios.get('AddUserInteraction.php?username=' + this.$cookie.getCookie("mdn") + '&content_id=' + this.$cookie.getCookie("Content_id") + '&interaction_type=view', {
                headers: {
                  'Authorization': config.authHeader,
                },
              }).then((response) => {
                if (response.data.error_code == 0) {
                  console.log("Add Interaction Success");
                  this.$cookie.setCookie('Content_id', publish.id);
                  this.$router.push("/Content");
                } else {
                  console.log("Add Interaction failed");
                  this.$cookie.setCookie('Content_id', publish.id);
                  this.$router.push("/Content");
                }
              });


              // this.$router.push("/Content");
            } else {
              this.$router.push("/Trailer");


            }
          });

      } else {
        this.$router.push("/Trailer");

      }
    },
  },

}
</script>
<style scoped>
.cardBox {
  width: auto;
  height: 26vh;
  position: relative;
  display: grid;
  place-items: center;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 10px 0px, rgba(0, 0, 0, 0.5) 0px 2px 15px 0px;
  margin-bottom: 1vh;
  margin-top: 1vh;
}

.card {
  position: absolute;
  width: 95%;
  height: 95%;
  background: #0a888e;
  border-radius: 20px;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #ffffff;
  overflow: hidden;
  padding: 0;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.5) 0px 18px 36px -18px inset;
  margin-top: 0;
}

.card h2,
span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  font-weight: 800;
  pointer-events: none;
  opacity: 0.7;
}

span {
  top: 50%;
}

.card .content h3 {
  font-size: 3rem;
  padding-bottom: 10px;
}

.card .content p {
  font-size: 1.6rem;
  line-height: 25px;
}

.card .content {
  transform: translateY(100%);
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.card:hover .content {
  transform: translateY(0);
  opacity: 1;
}

.cardBox::before {
  content: "";
  position: absolute;
  width: 40%;
  height: 150%;
  background: #40E0D0;
  background: -webkit-linear-gradient(to right, #029797, #42e8f4, #40e0e0);
  background: linear-gradient(to right, #029797, #42e8f4, #40e0e0);
  animation: glowing01 200s linear infinite;
  transform-origin: center;
  animation: glowing 200s linear infinite;
}

@keyframes glowing {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}


.title {
  font-family: 'Cairo', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 5px;
  transition: 0.5s;
  display: block;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  text-align: start;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  word-wrap: break-word;
  background-color: #047072;
  background-image: linear-gradient(0deg, #028d97 0, #71C6F7);
  padding: 8px 5px 5px 5px;
  border-radius: 12px;


}

.catalog2 {
  position: relative;
  padding: 5px 0 25px;
  padding-top: calc(50vh - 90%);
  padding-right: 0px;
  padding-bottom: 25px;
  padding-left: 0px;
  overflow: hidden;
  padding-bottom: calc(50vh - 60%);
}

.catalog__paginator-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 40px auto 0;
  width: 100%;
  height: 50px;
  border-radius: 16px;
  background-color: #151f30;
  background-color: #047072;
  background-image: linear-gradient(62deg, #047072 0%, #00c9c9 100%);
}



.yt__filter-slider-wrapper {
  position: relative
}

.yt__filter-slider-wrapper::before,
.yt__filter-slider-wrapper::after {
  position: absolute;
  font-size: 1.5rem;
  width: 8rem;
}




.yt__filter-slider {
  overflow-x: auto;
  padding-bottom: 1.25rem
}

.yt__filter-list {
  display: flex;
}

.yt__filter-list-item {
  padding: .5em .8em;
  background-color: #0000000d;
  border: 1px solid #0000001a;
  border-radius: 1.5rem;
  color: #59555507;
}

.yt__filter-list-item:first-child {
  margin-right: .4rem;
}

.yt__filter-list-item+.yt__filter-list-item {
  margin: 0 .4rem;
}

.yt__filter-list-item:hover {
  cursor: pointer;
  background-color: #0000001a;
}

.active {
  background-color: #60606000;
  color: #fff;
}

.active:hover {
  background-color: #90909000;
}




/*.catalog__paginator li {
    margin-left: 10%;
}
@media (max-width:360px){
.catalog2 {
    padding-top: 10%;
}
}
@media (max-width:320px){
.catalog2 {
    padding-top: 10%;
}
}
@media (max-width:768px){
.catalog2 {
    padding-top: 10%;
}
} */
</style>

<style>
@media (min-width: 768px) and (max-width: 991px) {

  /* Show 4th slide on md if col-md-4*/
  .carousel-inner .active.col-md-4.carousel-item+.carousel-item+.carousel-item+.carousel-item {
    position: absolute;
    top: 0;
    right: -33.3333%;
    /*change this with javascript in the future*/
    z-index: -1;
    display: block;
    visibility: visible;
  }
}

@media (min-width: 576px) and (max-width: 768px) {

  /* Show 3rd slide on sm if col-sm-6*/
  .carousel-inner .active.col-sm-6.carousel-item+.carousel-item+.carousel-item {
    position: absolute;
    top: 0;
    right: -50%;
    /*change this with javascript in the future*/
    z-index: -1;
    display: block;
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .carousel-item {
    margin-right: 0;
  }

  /* show 2 items */
  .carousel-inner .active+.carousel-item {
    display: block;
  }

  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)+.carousel-item {
    transition: none;
  }

  .carousel-inner .carousel-item-next {
    position: relative;
    transform: translate3d(0, 0, 0);
  }

  /* left or forward direction */
  .active.carousel-item-left+.carousel-item-next.carousel-item-left,
  .carousel-item-next.carousel-item-left+.carousel-item,
  .carousel-item-next.carousel-item-left+.carousel-item+.carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  /* farthest right hidden item must be also positioned for animations */
  .carousel-inner .carousel-item-prev.carousel-item-right {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    visibility: visible;
  }

  /* right or prev direction */
  .active.carousel-item-right+.carousel-item-prev.carousel-item-right,
  .carousel-item-prev.carousel-item-right+.carousel-item,
  .carousel-item-prev.carousel-item-right+.carousel-item+.carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
    visibility: visible;
  }

}

.carousel {
  position: relative;
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  direction: ltr;
}

/* MD */
@media (min-width: 768px) {

  /* show 3rd of 3 item slide */
  .carousel-inner .active+.carousel-item+.carousel-item {
    display: block;
  }

  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)+.carousel-item+.carousel-item {
    transition: none;
  }

  .carousel-inner .carousel-item-next {
    position: relative;
    transform: translate3d(0, 0, 0);
  }

  /* left or forward direction */
  .carousel-item-next.carousel-item-left+.carousel-item+.carousel-item+.carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  /* right or prev direction */
  .carousel-item-prev.carousel-item-right+.carousel-item+.carousel-item+.carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
    visibility: visible;
  }
}

/* LG */
@media (min-width: 991px) {

  /* show 4th item */
  .carousel-inner .active+.carousel-item+.carousel-item+.carousel-item {
    display: block;
  }

  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)+.carousel-item+.carousel-item+.carousel-item {
    transition: none;
  }

  /* Show 5th slide on lg if col-lg-3 */
  .carousel-inner .active.col-lg-3.carousel-item+.carousel-item+.carousel-item+.carousel-item+.carousel-item {
    position: absolute;
    top: 0;
    right: -25%;
    /*change this with javascript in the future*/
    z-index: -1;
    display: block;
    visibility: visible;
  }

  /* left or forward direction */
  .carousel-item-next.carousel-item-left+.carousel-item+.carousel-item+.carousel-item+.carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  /* right or prev direction //t - previous slide direction last item animation fix */
  .carousel-item-prev.carousel-item-right+.carousel-item+.carousel-item+.carousel-item+.carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
    visibility: visible;
  }
}

.pointer {
  cursor: pointer;
}

@import url(https://fonts.googleapis.com/css?family=Droid+Serif:400,700);
@import url(https://fonts.googleapis.com/css?family=Raleway:100,400,700);

/* Component Needs */
.pc-tab>input,
.pc-tab section>div {
  display: none;
}

#tab0:checked~section .tab0,
#tab1:checked~section .tab1,
#tab2:checked~section .tab2,
#tab3:checked~section .tab3,
#tab4:checked~section .tab4,
#tab5:checked~section .tab5,
#tab6:checked~section .tab6 {
  display: block;
}

#tab0:checked~nav .tab0,
#tab1:checked~nav .tab1,
#tab2:checked~nav .tab2,
#tab3:checked~nav .tab3,
#tab4:checked~section .tab4,
#tab5:checked~section .tab5,
#tab6:checked~section .tab6 {
  color: red;
}

/* Visual Styles */
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

h1 {
  text-align: center;
  font-weight: 100;
  font-size: 60px;
  color: #e74c3c;
}

.pc-tab {
  width: 100%;
  max-width: 100%;
  height: fit-content;
  margin: 0 auto;
}

.pc-tab ul {
  list-style: none;
  justify-content: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-right: 2%;
  padding-left: 2%;
}

.pc-tab ul li label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Zain" !important;
  color: #fff;
  background-image: linear-gradient(0deg, #047072 0, #00c9c9);
  margin-bottom: 0;
  cursor: pointer;
  border-radius: 10px;
  margin: 0 5px;
  position: relative;
  z-index: 100;
  transition: color 0.5s;
}

.pc-tab ul li label:hover {
  background-color: #52cbff;
}

.pc-tab ul li label:active {
  background-color: #52cbff;
}

.pc-tab ul li:not(:last-child) label {
  border-right-width: 0;
}

.pc-tab section {
  font-family: "Droid Serif";
  clear: both;
}

.pc-tab section div h2 {
  margin: 0;
  font-family: "Raleway";
  letter-spacing: 1px;
  color: #34495e;
}

#tab0:checked~nav .tab0 label,
#tab1:checked~nav .tab1 label,
#tab2:checked~nav .tab2 label,
#tab3:checked~nav .tab3 label,
#tab4:checked~nav .tab4 label,
#tab5:checked~nav .tab5 label,
#tab6:checked~nav .tab6 label {
  color: #1d3544;
  background-image: linear-gradient(0deg, #08dadd 0, #00c9c9);
  position: relative;
}

#tab0:checked~nav .tab0 label:after,
#tab1:checked~nav .tab1 label:after,
#tab2:checked~nav .tab2 label:after,
#tab3:checked~nav .tab3 label:after,
#tab4:checked~nav .tab4 label:after,
#tab5:checked~nav .tab5 label:after,
#tab6:checked~nav .tab6 label:after {
  content: "";
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #fff;
  right: 0;
  bottom: -1px;
}

footer {
  margin-top: 50px;
  font-size: 14px;
  color: #ccc;
  text-align: center;
}

footer a {
  color: #aaa;
  text-decoration: none;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(0deg, #047072 0, #00c9c9);
  border-radius: 18px;
  text-align: center;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.30), 0 15px 20px rgba(0, 0, 0, 0.22);
}

@media (max-width:540px) {
  .pc-tab ul {
    justify-content: flex-start;
  }
}

/* .flex {
     -webkit-box-flex: 1;
     -ms-flex: 1 1 auto;
     flex: 1 1 auto
 }

 @media (max-width:991.98px) {
     .padding {
         padding: 1.5rem
     }
 }

 @media (max-width:767.98px) {
     .padding {
         padding: 1rem
     }
 } */
</style>